//
// general.scss
//

body {
    color: $dark;
    background: $bg-body;
}
::selection {
    color: $dark;
}